/* Navbar styles */
.navbar {
    background-color: #2c3e50; /* Dark blue-gray */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px; /* Slightly rounded corners */
  }
  
  /* Menu list styles */
  .menu-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Menu item styles */
  .menu-item {
    margin-right: 20px;
    position: relative;
  }
  
  /* Link styles */
  .link {
    /* color: #ecf0f1;  Light gray */
    color: #2d7e92;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }


  
  .link:hover {
    color: #246d5f; /* Green accent */
  }
  
  /* Submenu styles */
  .submenu {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #34495e; /* Slightly darker blue-gray */
    list-style: none;
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
    z-index: 100;
  }
  
  /* Submenu item styles */
  .submenu-item {
    margin: 5px 0;
    white-space:nowrap;
  }
  
  .submenu-item .link {
    color: #ecf0f1; /* Light gray */
    font-weight: normal;
  }
  
  .submenu-item .link:hover {
    color: #3ca8e7; /* Red accent */
  }
  